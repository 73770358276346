import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import HlsIntegrationSettings from "./Components/HlsIntegrationSettings";

import {
  GET_SETTINGS,
  UPDATE_SETTINGS,
} from "../../../../helpers/apollo/utils";
import PositiveAction from "../../../../components/Button/PositiveAction";

import { useSnackbar } from "notistack";

const availableSiteIntegrations = [
  { name: "None", value: "NULL" },
  { name: "HLS - SFTP", value: "HLS" },
  { name: "Guestline", value: "Guestline" },
];

const fqdnRegEx =
  "(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9].)+[a-zA-Z]{2,63}$)";

const handleSave = ({
  selectedIntegration,
  hslSftpEndpoint,
  setHlsEndpointError,
  updateSettings,
}) => {
  if (hslSftpEndpoint.match(fqdnRegEx)) {
    updateSettings({
      variables: {
        input: {
          propertyManagementIntegration: selectedIntegration,
          hlsSftpEndpoint: hslSftpEndpoint,
        },
      },
    });
  } else {
    setHlsEndpointError("Endpoint is not a valid URL");
  }
};

const PropertyManagementSettings = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedIntegration, setSelectedIntegration] = useState("NULL");
  const [hslSftpEndpoint, setHslSftpEndpoint] = useState("");
  const [initialSelectedIntegration, setInitialSelectedIntegration] =
    useState("NULL");
  const [initialHslSftpEndpoint, setInitialHslSftpEndpoint] = useState("");
  const [hlsEndpointError, setHlsEndpointError] = useState("");

  useQuery(
    gql(GET_SETTINGS("propertyManagementIntegration, hlsSftpEndpoint")),
    {
      onCompleted: data => {
        setInitialHslSftpEndpoint(data.settings.hlsSftpEndpoint);
        setHslSftpEndpoint(data.settings.hlsSftpEndpoint);
        setInitialSelectedIntegration(
          data.settings.propertyManagementIntegration || "NULL",
        );
        setSelectedIntegration(
          data.settings.propertyManagementIntegration || "NULL",
        );
      },
    },
  );

  const [updateSettings] = useMutation(
    gql(UPDATE_SETTINGS("propertyManagementIntegration, hlsSftpEndpoint")),
    {
      onCompleted: data => {
        setInitialSelectedIntegration(
          data.updateSettings.propertyManagementIntegration,
        );
        setInitialHslSftpEndpoint(data.updateSettings.hlsSftpEndpoint);
        enqueueSnackbar("Settings saved", { variant: "success" });
      },
      onError: e => {
        enqueueSnackbar(`Error saving settings: ${e.message}`, {
          variant: "error",
        });
      },
    },
  );

  return (
    <>
      <TableRow>
        <TableCell sx={{ minWidth: "200px" }}>
          <Typography>Site Integration:</Typography>
        </TableCell>
        <TableCell>
          <Select
            sx={{ minWidth: "200px" }}
            value={selectedIntegration}
            onChange={({ target: { value } }) => setSelectedIntegration(value)}
            variant="standard"
            data-testid="site-integration-dropdown"
          >
            {availableSiteIntegrations.map(item => (
              <MenuItem
                key={item.name}
                value={item.value}
                selected={selectedIntegration === item.value}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
      </TableRow>
      {selectedIntegration === "HLS" && (
        <HlsIntegrationSettings
          hslSftpEndpoint={hslSftpEndpoint || ""}
          setHslSftpEndpoint={setHslSftpEndpoint}
          hlsEndpointError={hlsEndpointError}
          setHlsEndpointError={setHlsEndpointError}
        />
      )}
      <TableRow>
        <TableCell sx={{ borderBottom: "none", paddingTop: "50px" }}>
          <PositiveAction
            buttonText="Save"
            disabled={
              initialSelectedIntegration === selectedIntegration &&
              initialHslSftpEndpoint === hslSftpEndpoint
            }
            onClick={() =>
              handleSave({
                selectedIntegration,
                hslSftpEndpoint,
                setHlsEndpointError,
                updateSettings,
              })
            }
            testId="save-button"
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default PropertyManagementSettings;
